import React from 'react'
import { navigate } from '@reach/router'
import { s, colors, alpha } from '../style'
import { Link } from 'gatsby'
import { Grid, Hidden } from '@material-ui/core'

const { pages } = require('../config')

const MenuExpanded = ({ open, language, dispatch }) => {
  // const isSamePage = ({ isPartiallyCurrent }) =>
  //   isPartiallyCurrent && dispatch({ type: 'SET_MENU', action: false })

  const renderFromRange = (object, from, to) =>
    Object.values(object)
      .filter((page) => page.name && page.menuId)
      .filter((page) => page.menuId >= from && page.menuId <= to)
      .sort((a, b) => a.menuId - b.menuId)
      .map((page, id) => (
        <Grid item xs={12} md={6} key={id}>
          <div css={[sTextContainer, sPartContainer]} key={id}>
            <Link
              replace
              to={page.slug || page.hash}
              css={[sPage, open && sAnimatePage]}
              activeStyle={activeStylePage}
            >
              {page.name && page.name[language]}
            </Link>

            {page.sections &&
              page.sections?.map((section, id) => (
                <Hidden smDown key={id}>
                  <div
                    css={[sSection, open && sAnimateSection]}
                    onClick={() => {
                      dispatch({ type: 'TOGGLE_MENU' })
                      navigate(`${page.slug}#${section.hash}`)
                    }}
                    key={id}
                  >
                    {section.footer && section.footer[language]}
                  </div>
                </Hidden>
              ))}
          </div>
        </Grid>
      ))

  return (
    <nav css={[sNav, !open && { opacity: 0, pointerEvents: 'none' }]}>
      <Grid container css={{ justifyContent: 'center' }}>
        {renderFromRange(pages, 0, 3)}
        <Grid item xs={12} sm={12} md={6}>
          <div css={sPartContainer}>
            {Object.values(pages)
              .filter((page) => page.name && page.menuId)
              .filter((page) => page.menuId >= 4)
              .sort((a, b) => a.menuId - b.menuId)
              .map((page, id) => (
                <div css={sTextContainer} key={id}>
                  <Link
                    to={page.slug || `/#${page.hash}`}
                    css={[sPage, open && sAnimatePage]}
                    activeStyle={activeStylePage}
                  >
                    {page.name && page.name[language]}
                  </Link>

                  {page.sections &&
                    page.sections?.map((section, id) => (
                      <Hidden smDown key={id}>
                        <Link
                          css={[sSection, open && sAnimateSection]}
                          key={id}
                          to={page.slug + '#' + section.hash}
                          activeStyle={activeStylePage}
                        >
                          {section.footer && section.footer[language]}
                        </Link>
                      </Hidden>
                    ))}
                </div>
              ))}
          </div>
        </Grid>
      </Grid>
    </nav>
  )
}

const sNav = {
  zIndex: 999,
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  transition: 'opacity 0.3s ease-out',
  opacity: 1,
  background: `linear-gradient(170deg, ${colors.background1}, ${colors.background2})`,
  fontFamily: 'Quicksand',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const sTextContainer = {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '50%',
  margin: '0 auto',
  [s.sm_down]: { paddingBottom: '2rem', maxWidth: '75%' },
}

const sPartContainer = {
  [s.md]: { paddingBottom: '4rem' },
}

const sPage = {
  transition: 'all 0.3s ease-out',
  opacity: 0.3,
  [s.sm_down]: { fontSize: '2rem' },
  fontSize: '3rem',
  fontWeight: 300,

  textTransform: 'uppercase',
  [s.md]: { paddingBottom: '1rem' },
  '&:hover': {
    [s.hover]: { opacity: 1 },
  },
}

const activeStylePage = {
  opacity: 1,
  color: colors.yellow,
  textShadow: `0px 0px 16px ${colors.yellowOrange.concat(alpha[100])}`,
}

const sSection = {
  cursor: 'pointer',
  transition: 'all 0.3s ease-out',
  opacity: 0.3,
  fontSize: '1rem',
  paddingBottom: '0.5rem',
  fontWeight: 300,
  textTransform: 'uppercase',
  '&:hover': {
    [s.hover]: { opacity: 1 },
  },
}

const sAnimatePage = {
  // animation: 'sAnimatePage 0.4s ease-in-out',
}

const sAnimateSection = {
  // animation: 'sAnimateSection 0.4s ease-in-out'
}

export default MenuExpanded
