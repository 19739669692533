/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { StateContext, DispatchContext } from '../context'

import Menu from './menu'
import MenuExpanded from './menuexpanded'
import Footer from './footer'
import Cookies from './cookies'
import './layout.css'
import '../static/fonts/fonts.css'

import { colors, themes } from '../style'

const Layout = ({ children }) => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  const language = state && state.language ? state.language : 'pl'
  const menuOpen = state?.menuOpen
  const theme = state && state.theme ? state.theme : 'dark'
  const isScrollTop = state?.isScrollTop

  useEffect(() => {
    if (window)
      window.onscroll = function () {
        if (window.pageYOffset <= 50)
          !isScrollTop &&
            dispatch({ type: 'SET_SCROLL_TOP', payload: true }) &&
            dispatch({ type: 'SET_MENU', payload: false })
        else isScrollTop && dispatch({ type: 'SET_SCROLL_TOP', payload: false })
      }
    // console.log(isScrollTop ? 'true' : 'false')
    return () => (window.onscroll = null)
  }, [isScrollTop, dispatch])

  // console.log(menuOpen)

  return (
    <>
      <Menu
        language={language}
        theme={theme}
        dispatch={dispatch}
        isScrollTop={isScrollTop}
        menuOpen={menuOpen}
      />
      <div
        css={{
          zIndex: -1,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          color: colors.white,
          background: `linear-gradient(170deg, ${themes[theme].background1}, ${themes[theme].background2})`,
          // background: 'black'
        }}
      ></div>
      <div css={[sMain, menuOpen && { overflowY: 'hidden' }]}>
        <main>{children}</main>
      </div>
      <MenuExpanded
        open={menuOpen}
        language={language}
        theme={theme}
        dispatch={dispatch}
      />
      <Footer language={language} theme={theme} dispatch={dispatch} />
      <Cookies />
    </>
  )
}

const sMain = {
  fontFamily: 'Quicksand',
  margin: `0 auto`,
  // maxWidth: 1280, // working?
  zIndex: 1,
  section: {
    maxWidth: 960,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
