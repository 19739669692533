export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return phoneNumberString
}
// formatPhoneNumber('+12345678900') // => "+1 (234) 567-8900"
// formatPhoneNumber('2345678900')   // => "(234) 567-8900"
