import React from 'react'
import { Link } from 'gatsby'
import MenuButton from '../components/menuButton'
import ThemeButton from '../components/themeButton'
import LanguageButton from '../components/languageButton'
import appclever from '../assets/icons/appclever'
import { s, colors } from '../style'

const Menu = ({ dispatch, menuOpen, theme, language, isScrollTop }) => {
  return (
    <div css={sMenu}>
      <div css={[sLogo, (isScrollTop === false || menuOpen) && sLogoScrolled]}>
        <Link to="/">{appclever}</Link>
      </div>
      <div
        css={[
          sButtons,
          (typeof isScrollTop === 'undefined' || (isScrollTop && !menuOpen)) &&
            sButtonScrollTop,
        ]}
      >
        {/* <div onClick={() => dispatch({ type: 'TOGGLE_LANGUAGE' })}>
          <LanguageButton language={language} theme={theme} />
        </div>
        <div onClick={() => dispatch({ type: 'TOGGLE_THEME' })}>
          <ThemeButton theme={theme} />
        </div> */}
        <div onClick={() => dispatch({ type: 'TOGGLE_MENU' })}>
          <MenuButton open={menuOpen} />
        </div>
      </div>
    </div>
  )
}

const sMenu = {
  zIndex: 1001,
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
}

const sLogo = {
  position: 'absolute',
  transition: 'transform 0.3s ease-in-out, left 0.3s ease-in-out',
  zIndex: 1001,
  '&:hover': {
    [s.hover]: {
      textShadow: `0px 0px 24px ${colors.white}`,
    },
  },

  [s.sm_down]: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, 100%)',
  },

  [s.md]: {
    top: '2rem',
    left: '2rem',
    transform: 'scale(0.8)',
  },
}

const sLogoScrolled = {
  [s.sm_down]: { left: '6rem', transform: 'translate(-50%, 40%) scale(0.7)' },
  // [s.xs]: { transform: 'translate(-95%, 35%) scale(0.7)',},
  // [s.ss]: { transform: 'translate(-85%, 40%) scale(0.6)', },
}

const sButtons = {
  position: 'absolute',
  transition: 'opacity 0.3s ease-in-out, background 0.3s ease-in-out',
  zIndex: 1000,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100vw',
  margin: '0 auto',
  marginRight: '0.5rem',
  padding: '2rem',
  [s.sm_down]: { padding: '1rem', marginRight: '0' },
  [s.md]: { paddingRight: '2rem' },
  [s.md_down]: { backdropFilter: 'blur(20px)' },
  '& > div': {
    margin: '0 0.5rem',
    [s.sm_down]: { margin: '0 0.25rem' },
    [s.ss]: { margin: 0 },
  },
}

const sButtonScrollTop = {
  background: 'transparent',
  [s.sm_down]: { opacity: 0, pointerEvents: 'none' },
}

export default Menu
