const slugs = {
  index: '/',
  about: '/poznaj',
  coop: '/wspolpraca',
  services: '/uslugi',
  valuation: '/wycena',
  career: '/kariera',
  contact: '/kontakt',
  policy: '/polityka-prywatnosci',
  download: '/do-pobrania',
}

const hashes = {
  aboutDifferent: 'co-nas-wyroznia',
  aboutFuture: 'nasza-przyszlosc',
  aboutWhyUs: 'dlaczego-appclever',
  servicesB2B: 'b2b',
  servicesWebApps: 'web',
  servicesDesign: 'design',
  servicesB2C: 'b2c',
  servicesDedicated: 'dedykowane',
  collabModels: 'modele',
  collabStages: 'etapy',
  techstack: 'tech-stack',
  career: 'kariera',
}

const introSection = {
  motto: {
    pl: [
      'For',
      { text: 'Business', special: true },
      'with',
      { text: 'Clever', special: true },
      'ideas',
    ],
    en: [
      'For',
      { text: 'Business', special: true },
      'with',
      { text: 'Clever', special: true },
      'ideas',
    ],
  },
  description: {
    pl:
      'Tworzymy aplikacje webowe w oparciu o potrzeby Twojego Biznesu. Przekaż nam swoją wizję i pozwól jej się rozwijać',
    en:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
  },
  button: {
    text: {
      pl: 'MAKE YOUR BUSINESS CLEVER',
      en: 'MAKE YOUR BUSINESS CLEVER',
    },
    internal: true,
    to: slugs.services,
  },
}

const aboutSection = {
  name: {
    pl: 'Poznaj Nas',
    en: 'Discover Us',
  },
  description: {
    pl:
      'AppClever jest zespołem programistów, projektantów oraz doświadczonych managerów, którzy każdego dnia dążą do doskonalenia swoich umiejętności i procesów twórczych. Dedykowane aplikacje powstają z pasją, a każdy projekt skupia na sobie uwagę całej drużyny. Szeroki wachlarz doświadczeń umożliwia kreowanie rozwiązań, które przekładają się na satysfakcję klientów AppClever',
    en:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  sections: [
    {
      title: {
        pl: [
          'Co takiego',
          { text: 'wyróżnia', special: true },
          'nas od innych?',
        ],
        en: [
          'What makes us',
          { text: 'stand out', special: true },
          'from others?',
        ],
      },
      footer: {
        pl: 'Co nas wyróżnia',
        en: 'What makes us stand out',
      },
      hash: hashes.aboutDifferent,
      text: {
        pl:
          'Wierzymy, że odpowiednia technologia, w połączeniu z intuicyjnym interfejsem oraz przemyślaną strategią rozwoju może znacząco pomóc w rozwoju biznesu, zarówno indywidualistów, małych firm, jak i dużych organizacji. Szczególną uwagę skupiamy na użytkownikach docelowych. To właśnie oni są odbiorcami systemów, platform i Serwisów, które tworzymy. Niezwykła dbałość o szczególy i dokładność realizowanych przedsięwzięć są naszymi atutami, których mogą pozazdrościć inni',
        en:
          "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
      },
      button: {
        text: {
          pl: 'Sprawdź jak działamy',
          en: 'See how we work',
        },
        link: {
          internal: true,
          to: slugs.coop,
        },
      },
    },
    // {
    //   title: {
    //     pl: [{ text: 'Przyszłość', special: true }, 'firmy?'],
    //     en: [
    //       `The`,
    //       { text: 'future', special: true },
    //       'of the?',
    //       { text: 'company', special: true },
    //     ],
    //   },
    //   footer: {
    //     pl: 'Przyszłość Firmy',
    //     en: "Company's Future",
    //   },
    //   hash: hashes.aboutFuture,
    //   text: {
    //     pl: 'Spółka AppClever powstała w 2020 roku...',
    //     en:
    //       'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.',
    //   },
    // },
    {
      title: {
        pl: ['Dlaczego', { text: 'AppClever', special: true }, '?'],
        en: ['Why', { text: 'AppClever', special: true }, '?'],
      },
      footer: {
        pl: 'Dlaczego AppClever',
        en: 'Why AppClever',
      },
      hash: hashes.aboutWhyUs,
      text: {
        pl:
          'Naszym celem jest wsparcie i rozwój biznesu w oparciu o technologię, która otwiera możliwości niemalże dla każdej firmy. Usprawnianie procesów poprzez wdrażanie innowacji technologicznych to według założycieli AppClever najlepsza droga do szybkiego wzrostu i optymalizacji. Nasz zespół każdego dnia pracuje nad budową aplikacji i narzędzi IT dla biznesu',
        en:
          'Naszym celem jest wsparcie i rozwój biznesu w oparciu o technologię, która otwiera możliwości niemalże dla każdej firmy. Usprawnianie procesów poprzez wdrażanie innowacji technologicznych to według założycieli AppClever najlepsza droga do szybkiego wzrostu i optymalizacji. Nasz zespół każdego dnia pracuje nad budową aplikacji i narzędzi IT dla biznesu',
      },
      itemsLarge: [
        {
          id: 1,
          title: { pl: 'Strategy Plan', en: 'Strategy Plan' },
          icon: 'prototype',
          text: {
            pl: '',
            en: '',
          },
        },
        {
          id: 2,
          title: { pl: 'Detailed Design', en: 'Detailed Design' },
          icon: 'materialdesign',
          text: {
            pl: '',
            en: '',
          },
        },
        {
          id: 2,
          title: { pl: 'Scalable Code', en: 'Scalable Code' },
          icon: 'code',
          text: {
            pl: '',
            en: '',
          },
        },
      ],
    },
  ],
}

const servicesSection = {
  name: {
    pl: 'Usługi',
    en: 'Services',
  },
  description: {},
  sections: [
    {
      title: {
        pl: [
          'Rozwiązania',
          { text: 'IT', special: true },
          'dla klientów',
          { text: 'B2B', special: true },
        ],
        en: [
          { text: 'IT', special: true },
          'solutions for',
          { text: 'B2B', special: true },
          'clients',
        ],
      },
      footer: {
        pl: 'Rozwiązania IT dla klientów B2B',
        en: 'IT solutions for B2B clients',
      },
      hash: hashes.servicesB2B,
      text: {
        pl:
          'Projektując aplikacje webowe, udoskonalamy procesy obsługiwane w sferze online. Dzięki dostosowaniu technologii i kreatywnych rozwiązań umożliwiamy rozwijanie się koncepcji biznesowych. Od konceptu, poprzez dokumentacje, makiety oraz prototyp graficzny, aż po przygotowanie skalowalnego kodu i implementację rozwiązania dedykowanego dla Ciebie. Tak właśnie wygląda proces tworzenia w AppClever. Zapoznaj się z poszczególnymi elementami:',
        en:
          "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when  centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      },
      button: {
        text: {
          pl: 'Rozpocznijmy współpracę',
          en: "Let's collaborate",
        },
        link: {
          internal: true,
          to: slugs.contact,
        },
      },
      itemsSmall: [
        {
          id: 1,
          title: { pl: 'Koncept', en: 'Concept' },
          description: {
            pl:
              'Jeżeli Twoja wizja jest niepełna, lub niektóre jej części są niejasne - nasz zespół pomoże Ci podjąć decyzję i przedstawi alternatywne rozwiązania techniczne. Wspólnie powiążemy Twój biznes z odpowiednim produktem cyfrowym',
            en: 'Description EN',
          },
          icon: 'light',
        },
        {
          id: 2,
          title: { pl: 'Modele UML', en: 'UML Models' },
          description: {
            pl:
              'Korzystając ze standardów UML, analizujemy i modelujemy systemy informatyczne - dostarczymy Ci liczne diagramy, które umożliwią zrozumienie działania aplikacji, złożonych struktur, komponentów wraz z ich stanami, przypadków użycia i interakcji',
            en: 'Description EN',
          },
          icon: 'document',
        },
        {
          id: 3,
          title: { pl: 'Specyfikacja', en: 'Specification' },
          description: {
            pl:
              'Specyfikacja (SRS) to dokumentacja opisująca wszelkie aspekt oprogramowania. Od definicji celów i założeń, poprzez opisy porządanych i nieporządanych działań modułów, metodologię implementacji, aż po wyjaśnienie komunikacji z użytkownikiem poprzez interfejs aplikacyjny. Specyfikacja to jednoznaczny, kompletny i modyfikowalny przepis, na zbudowanie Twojego produktu',
            en: 'Description EN',
          },
          icon: 'documents',
        },
        {
          id: 4,
          title: { pl: 'Makiety', en: 'Models' },
          description: {
            pl:
              'Makiety to zbiór konceptualnych ilustracji przedstawiających podstawowy wygląd, układ i styl interfejsu aplikacji. Z uwagi na to, że Twój produkt może być używany na różnych urządzeniach, przygotujemy Ci makiety w różnych wersjach. Ich ilość zależy od złożoności produktu. Zależy nam, aby interfejs produktu podobał się Tobie, zanim zostanie zaimplementowany ',
            en: 'Description EN',
          },
          icon: 'pictures',
        },
        {
          id: 5,
          title: { pl: 'Prototyp', en: 'Prototype' },
          description: {
            pl:
              'Na podstawie makiet, modeli oraz dokumentacji projektujemy prototyp graficzny, który pozwoli częściowo doświadczyć działania systemu. Intuicyjny, responsywny, estetyczny - to cechy każdego designu, który kreujemy',
            en: 'Description EN',
          },
          icon: 'prototype',
        },
        {
          id: 6,
          title: { pl: 'Kod', en: 'Code' },
          description: {
            pl:
              'Budujemy rozszerzalną, wydajną i integralną architekturę. Uważamy, że skalowalny kod to kluczowy element jakościowego systemu informatycznego. Oprogramowanie wówczas jest przygotowane do modyfikacji i ewolucji, które mogą pojawić się w czasie poprzez zmiany na rynku, środowisku docelowym lub na podstawie dodatkowych wymagań klientów',
            en: 'Description EN',
          },
          icon: 'code',
        },
      ],
    },
    {
      title: {
        pl: ['Aplikacje', { text: 'webowe', special: true }, 'dla marketingu'],
        en: [{ text: 'Web', special: true }, 'applications for marketing'],
      },
      footer: {
        pl: 'Aplikacje webowe dla marketingu',
        en: 'Web applications for marketing',
      },
      hash: hashes.servicesWebApps,
      text: {
        pl:
          'Nowoczesne rozwiązania stosowane w marketingu wymagają wsparcia nowoczesnych technologii. Właśnie dlatego oferujemy outsourcing procesów IT dla marketingu. Sprawdź przykładowe realizacje AppClever stworzone do realizacji działań marketingowych',
        en:
          "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when  centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      },
      button: {
        text: {
          pl: 'Zleć nam projekt aplikacji',
          en: 'Valuate App Project',
        },
        link: {
          internal: true,
          to: slugs.valuation,
        },
      },
      itemsSmall: [
        {
          id: 1,
          title: { pl: 'Single Page App', en: 'Single Page App' },
          description: {
            pl:
              'SPA to aplikacja webowa "jednej strony", gdzie cała treść wyświetlana jest w przeglądarce w sposób dynamiczny. Szczególną uwagę przywiązujemy do aspektów wizualnych i przyciągających uwagę grafik. Usługa może zostać wykorzystana do budowy strony wizytówkowej, informacyjnej lub prostego produktu marketingowego np. formularzu kontaktowego z klientem',
            en:
              'Description EN Description EN Description ENDescription ENDescription ENDescription ENDescription ENDescription ENDescription ENDescription ENDescription ENDescription ENDescription ENDescription ENDescription EN',
          },
          selectOption: 'spa',
          icon: 'reactjs',
        },
        {
          id: 2,
          title: { pl: 'Web App + APIs', en: 'Web App + APIs' },
          description: {
            pl:
              'Aplikacja webowa w połączeniu z mikroSerwisami API to produkt przeznaczony do zaawansowanych rozwiązań. System składa się z licznych modułów sieciowych, baz danych i interfejsów graficznych. Cały ekosystem tworzy rozbudowane narzędzie do przetwarzania i monitorowania informacji. Produkt przeznaczony dla biznesów chcących wdrożyć się w sferę online lub usprawnić i zwiększyć wydajność istniejących procesów w danej branży',
            en:
              'Aplikacja webowa w połączeniu z mikroSerwisami API to produkt przeznaczony do zaawansowanych rozwiązań. System składa się z licznych modułów sieciowych, baz danych i interfejsów graficznych. Cały ekosystem tworzy rozbudowane narzędzie do przetwarzania i monitorowania informacji. Produkt przeznaczony dla biznesów chcących wdrożyć się w sferę online lub usprawnić i zwiększyć wydajność istniejących procesów w danej branży',
          },
          selectOption: 'webapp',
          icon: 'graphql',
        },
        {
          id: 3,
          title: { pl: 'Progressive Web App', en: 'Progressive Web App' },
          description: {
            pl:
              'PWA, to progresywna aplikacja webowa, która nie dość, że potrafi działać w trybie offline, to dodatkowo może zostać wyposażona w rzeszę funkcjonalności znanych z aplikacji natywnych na urządzenia mobilne np. dostęp do modułów: Geolokalizacji, Bluetooth, systemu powiadomień PUSH, płatności, sprzętu audio i video czy gestów. Rozwiązanie w znacznej części może zastąpić aplikację mobilną i jest uznawane za przyszłość aplikacji internetowych',
            en:
              'PWA, to progresywna aplikacja webowa, która nie dość, że potrafi działać w trybie offline, to dodatkowo może zostać wyposażona w rzeszę funkcjonalności znanych z aplikacji natywnych na urządzenia mobilne np. dostęp do modułów: Geolokalizacji, Bluetooth, systemu powiadomień PUSH, płatności, sprzętu audio i video czy gestów. Rozwiązanie w znacznej części może zastąpić aplikację mobilną i jest uznawane za przyszłość aplikacji internetowych',
          },
          selectOption: 'pwa',
          icon: 'pwa',
        },
        {
          id: 4,
          title: { pl: 'Web Blog + CMS', en: 'Web Blog + CMS' },
          description: {
            pl:
              'Usługa przeznaczona dla klientów, którzy planują stworzyć Serwis typu blog, w którym liczne wpisy, artykuły, posty oraz media będą często publikowane. CMS (system zarządzania treścią) jest prostym narzędziem do rozbudowy i edytowania zawartości na wielu stronach i podstronach internetowych',
            en:
              'Description EN Description EN Description ENDescription ENDescription ENDescription ENDescription ENDescription ENDescription ENDescription ENDescription ENDescription ENDescription ENDescription ENDescription EN',
          },
          selectOption: 'blogcms',
          icon: 'gatsbyjs',
        },
        {
          id: 5,
          title: { pl: 'Sklep E-Commerce', en: 'E-Commerce Store' },
          description: {
            pl:
              'Biznes nastawiony na sprzedaż produktów lub usług może zostać przeniesiony do sieci, dzięki platformie E-Commerce. Aplikacja prezentuje użytkownikom dostępne produkty oraz umożliwia przeprowadzanie transakcji finansowych w wygodny i bezpieczny sposób. Właściciel takiego Serwisu, otrzymuje dostęp do bazy danych poprzez dedykowaną aplikację, w której w łatwy sposób zarządza ofertą, promocjami i transakcjami platformy',
            en: 'Biznes ',
          },
          selectOption: 'ecommerce',
          icon: 'nodejs',
        },
        {
          id: 6,
          title: { pl: 'WordPress Page', en: 'WordPress Page' },
          description: {
            pl:
              'Witryny stworzone w technologii WordPress cechują się prostotą i łatwością implementacji. Może to być zarówno strona portfolio, wizytówka, jak i niewielki sklep online. Dzięki ogromnej ilości wtyczek strona może zostać w dowolnym momencie rozbudowana',
            en:
              'Description EN Description EN Description ENDescription ENDescription ENDescription ENDescription ENDescription ENDescription ENDescription ENDescription ENDescription ENDescription ENDescription ENDescription EN',
          },
          selectOption: 'wordpress',
          icon: 'wordpress',
        },
      ],
    },
    {
      title: {
        pl: ['Design', { text: 'UX / UI', special: true }],
        en: ['Design', { text: 'UX / UI', special: true }],
      },
      footer: {
        pl: 'Design UX / UI',
        en: 'Design UX / UI',
      },
      hash: hashes.servicesDesign,
      text: {
        pl:
          "Usługi typu 'Design' to nie tylko szablony i makiety stron WWW lub aplikacji internetowych. To sposób, który pozwala zilustrować system oraz jego funkcjonalności. Prototyp graficzny umożliwia poznanie zachowań użytkowników, którzy będą korzystali z Twojego rozwiązania. Interaktywne, responsywne i nadzwyczaj estetyczne interfejsy nie tylko pomogą zaprzyjaźnić potencjalnych odbiorców z aplikacją, ale także przykują uwagę niejednego klienta. Wyróżnij swój projekt oryginalnym wyglądem",
        en:
          'When  centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      },
      button: {
        text: {
          pl: 'Zleć nam projekt graficzny',
          en: 'Valuate Design Project',
        },
        link: {
          internal: true,
          to: slugs.valuation,
        },
      },
      itemsSmall: [
        {
          id: 1,
          title: { pl: 'Projekt Graficzny', en: 'Design Project' },
          description: {
            pl:
              'Jest zbiorem grafik i wizualizacji interfejsu aplikacyjnego lub strony internetowej. Projektując Design UI, definujemy nastepujące element: układ podstron i sekcji; dobór czcionek tytułów, podtytułów, opisów; wystylowane komponenty, bannery, ikony, przyciski i elementy interaktywne; dobrane motywy i warianty kolorystyczne. Postępujemy zgodnie z kanonem HIG oraz MD, pamiętając o responsywności, czytelności, ale zarazem atrakcyjności styli',
            en: 'Description EN',
          },
          selectOption: 'adobexd',
          icon: 'adobexd',
        },
        {
          id: 2,
          title: { pl: 'Prototyp UX/UI', en: 'UX / UI Prototype' },
          description: {
            pl:
              'Jeżeli sam projekt graficzny nie wystarcza, możemy przygotować tzw. "Prototyp Graficzny", czyli interaktywny design interfejsu, w którym elementy zaprojektowane są w dynamicznych wariacjach, a przejścia pomiędzy stanami komponentów i widokami zostały animowane. Wszystko po to, aby zwizualizować doświadczenie (UX) rzeczywistego produktu',
            en: 'Description EN',
          },
          selectOption: 'prototype',
          icon: 'prototype',
        },
        {
          id: 3,
          title: { pl: 'Animacje SVG', en: 'SVG Animations' },
          description: {
            pl:
              'Jesteśmy zdania, że klasyczne logotypu kończą swoją dominację, dlatego w naszej ofercie dostępna jest usługa wykonania animowanych grafik wektorowych, które ożywią znak rozpoznawalny każdej marki. Niezależnie czy chcesz wykorzystać animację na stronę internetowej, w video, czy reklamie - zaprojektujemy i zaimplementujemy dowolną strukturę',
            en: 'Description EN',
          },
          selectOption: 'designconsulting',
          icon: 'svg',
        },
        {
          id: 4,
          title: { pl: 'Konsultacje UX / UI', en: 'UX / UI Consulting' },
          description: {
            pl:
              'Cenimy kreatywność i dążymy do perfekcji, jeśli mowa o estetyce, czy pięknie. Z drugiej strony doskonale rozumiemy, jak ważnymi elementami są responsywność i zrozumiałość interfejsów, dlatego chętnie podzielimy się własnym doświadczeniem i pomożemy polepszyć projekty naszych klientów',
            en: 'Description EN',
          },
          selectOption: 'designconsulting',
          icon: 'materialdesign',
        },
      ],
    },
    {
      title: {
        pl: [
          'Produkty',
          { text: 'digitalowe', special: true },
          'na rynku',
          { text: 'B2C', special: true },
        ],
        en: [
          { text: 'Digital', special: true },
          'products for',
          { text: 'B2C', special: true },
          'market',
        ],
      },
      footer: {
        pl: 'Produkty digitalowe na rynku B2C',
        en: 'Digital products for B2C market',
      },
      hash: hashes.servicesB2C,
      text: {
        pl:
          'AppClever w ramach swojej działalności zajmuje się produkcją rozwiązań digitalowych dla rynku konsumentów detalicznych. W ich skład wchodzą aplikacje internetowe publikowane na szeroką skalę, które umożliwiają klientom dostęp do nowoczesnych rozwiązań mobilnych, zawierających unikalne i nowatorskie funkcjonalności',
        en:
          'When  centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      },
      // button: {
      //   text: {
      //     pl: 'Dowiedź się więcej',
      //     en: 'Learn more',
      //   },
      //   link: {
      //     internal: true,
      //     to: slugs.about,
      //   },
      // },
    },
    {
      title: {
        pl: ['Rozwiązania', { text: 'dedykowane', special: true }],
        en: [{ text: 'Dedicated', special: true }, 'solutions'],
      },
      footer: {
        pl: 'Rozwiązania dedykowane',
        en: 'Dedicated solutions',
      },
      hash: hashes.servicesDedicated,
      text: {
        pl:
          'AppClever świadczy usługi dedykowane, czyli takie, które dopasowane są indywidualnie do potrzeb klienta. Projekt, który jest na tyle innowacyjny i rozbudowany nie będzie stanowił dla nas problemu. Specjaliści AppClever sprostają każdemu wyzwaniu',
        en:
          'When  centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      },
      button: {
        text: {
          pl: 'Nawiąż kontakt',
          en: 'Contact us',
        },
        link: {
          internal: true,
          to: slugs.contact,
        },
      },
    },
  ],
}

const valuationSection = {
  name: {
    pl: 'Wycena Projektu',
    en: 'Project Valuation',
  },
  description: {
    pl:
      'Niezależnie czy masz nowy pomysł na internetowy biznes, chciałbyś rozwinąć istniejące usługi w sferze online, czy potrzebujesz wsparcia w budowie systemu informatycznego lub aplikacji webowej. Korzystając z poniższego formularza, poznamy wstępne informacje o Twoim projekcie. Zespół AppClever skontaktuje się z Tobą drogą mailową lub telefonicznie i przygotuje dla Ciebie optymalną ofertę. Twój biznes jest krok o bycia "Clever" !',
    en:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
  },
  helpSection: {
    headline: {
      pl: 'Problem z wyborem kategorii produktu?',
      en: 'Troubles choosing product categories?',
    },
    text: {
      pl: 'Sprawdź nasze usługi',
      en: 'Check out our services',
    },
    link: slugs.services + '#' + hashes.servicesWebApps,
  },
  formSections: {
    categories: {
      number: 1,
      title: {
        pl: 'Kategorie Produktu',
        en: 'Product Categories',
      },
      options: {
        spa: {
          name: { pl: 'Single Page App', en: 'Single Page App' },
          icon: 'reactjs',
        },
        webapp: {
          name: { pl: 'Web App + APIs', en: 'Web App + APIs' },
          icon: 'graphql',
        },
        pwa: {
          name: { pl: 'Progressive Web App', en: 'Progressive Web App' },
          icon: 'pwa',
        },
        blogcms: {
          name: { pl: 'Web Blog + CMS', en: 'Web Blog + CMS' },
          icon: 'gatsbyjs',
        },
        wordpress: {
          name: { pl: 'Wordpress Site', en: 'Wordpress Site' },
          icon: 'wordpress',
        },
        ecommerce: {
          name: { pl: 'Sklep E-Commerce', en: 'E-Commerce Store' },
          icon: 'nodejs',
        },
        adobexd: {
          name: { pl: 'Projekt Design', en: 'Design Project' },
          icon: 'adobexd',
        },
        designconsulting: {
          name: { pl: 'Konsultacja UX / UI', en: 'UX / UI Consulting' },
          icon: 'materialdesign',
        },
      },
    },
    elements: {
      number: 2,
      title: {
        pl: 'Istniejące Elementy',
        en: 'Existing Elements',
      },
      options: {
        concept: { name: { pl: 'Koncept', en: 'Concept' }, icon: 'light' },
        description: {
          name: { pl: 'Opisy / Modele', en: 'Product Description' },
          icon: 'document',
        },
        docs: {
          name: { pl: 'Dokumentacja / SRS', en: 'Docs / SRS' },
          icon: 'documents',
        },
        models: { name: { pl: 'Makiety', en: 'Models' }, icon: 'pictures' },
        design: {
          name: { pl: 'Projekt Graficnzy', en: 'Design Project' },
          icon: 'adobexd',
        },
        prototype: {
          name: { pl: 'Prototyp Design', en: 'Design Prototype' },
          icon: 'prototype',
        },
        code: { name: { pl: 'Kod', en: 'Code' }, icon: 'code' },
      },
    },
    details: {
      number: 3,
      title: {
        pl: 'Szczegóły Produktu',
        en: 'Product Details',
      },
    },
    budget: {
      number: 4,
      title: {
        pl: 'Budżet',
        en: 'Budget',
      },
    },
    agreement: {
      number: 5,
      title: {
        pl: 'Zgoda',
        en: 'Agreement',
      },
    },
  },
}

const contactSection = {
  name: {
    pl: 'Kontakt',
    en: 'Contact',
  },
  description: {
    pl:
      "Masz nowy pomysł lub wizję rozwoju istniejącego rozwiązania biznesowego? Potrzebujesz wsparcia specjalistów lub pozyskania zespołu deweloperskiego? Pragniesz zbudować precyzyjny system, który poprawi procesy Twojej działalności? Skontaktuj się z nami! Ustalimy termin spotkania i sprawimy, aby Twój biznes stał się 'Clever'",
    en:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
  },
}

const coopModelsSection = {
  name: {
    pl: 'Modele Współpracy',
    en: 'Cooperation Models',
  },
  description: {},
  hash: hashes.collabModels,
  footer: {
    pl: 'Modele Współpracy',
    en: 'Cooperation Models',
  },
  list: [
    {
      title: {
        pl: ['Outsourcing', { text: 'IT', special: true }],
        en: [{ text: 'IT', special: true }, 'Outsourcing'],
      },
      footer: {
        pl: 'Outsourcing IT',
        en: 'IT Outsourcing',
      },
      text: {
        pl:
          'Niezależnie od tego, na jakim etapie obecnie znajduje się Twój projekt, możemy przekształcić go w zadanie dla naszego zespołu. Po określeniu celów i omówieniu harmonogramu pracy, rozpoczniemy współpracę w pełnym zakresie. Na każdym etapie umożliwimy dysponowaniem  (określonym wcześniej) czasem pracy naszych specjalistów',
        en:
          'Niezależnie od tego na jakim etapie projektu jesteś obecnie możemy przekształcić go w zadanie dla naszego zespołu. Określasz cele, omawiamy harmonogram i rozpoczynamy współpracę w  pełnym zakresie. Na każdym etapie masz do dyspozycji określoną wcześniej liczbę godzin naszych specjalistów.',
      },
      icon: 'accessability',
    },
    {
      title: {
        pl: ['Potrzebuję', { text: 'zespołu', special: true }],
        en: [{ text: 'Dev Team', special: true }, 'needed'],
      },
      footer: {
        pl: 'Zespół deweloperski',
        en: 'Dev Team needed',
      },
      text: {
        pl:
          'To rozwiązanie świetnie sprawdza się w momencie, gdy masz już swój zespół i wiedzę technologiczną. Jeśli planujesz stały rozwój swojego rozwiązania to na pewno będzie odpowiedni model współpracy',
        en:
          'To rozwiązanie świetnie sprawdza się w momencie gdy masz już swój zespół i wiedzę technologiczną. Jeśli planujesz stały rozwój swojego rozwiązania to na pewno będzie odpowiedni model współpracy.',
      },
      icon: 'people',
    },
    {
      title: {
        pl: ['Potrzebuję', { text: 'specialisty', special: true }],
        en: [{ text: 'Specialist', special: true }, 'needed'],
      },
      footer: {
        pl: 'Specjaliści',
        en: 'Specialist needed',
      },
      text: {
        pl:
          'Na niemalże każdym etapie projektu możesz potrzebować konsultacji lub audytu zakończonego etapu. Dzięki naszemu wsparciu zyskasz dostęp do wiedzy potrzebnej do szybszego rozwoju Twojego biznesu.',
        en:
          'Na niemalże każdym etapie projektu możesz potrzebować konsultacji lub audytu zakończonego etapu. Dzięki naszemu wsparciu zyskasz dostęp do wiedzy potrzebnej do szybszego rozwoju Twojego biznesu.',
      },
      icon: 'person',
    },
  ],
  button: {
    text: {
      pl: 'Dowiedz się więcej',
      en: 'Learn More',
    },
    link: {
      internal: true,
      to: slugs.coop + '#' + hashes.collabStages,
    },
  },
}

const coopStagesSection = {
  name: {
    pl: 'Etapy Współpracy',
    en: 'Cooperation Stages',
  },
  description: {},
  hash: hashes.collabStages,
  footer: {
    pl: 'Etapy Współpracy',
    en: 'Cooperation Stages',
  },
  list: [
    {
      title: {
        pl: ['Analiza'],
        en: ['Analysis'],
      },
      subtitle: {
        pl: 'Etap 1',
        en: 'Stage 1',
      },
      unorderedList: {
        pl: [
          'Etap Analizy ma na celu pozyskania informacji o Twoim pomyśle. Wspólnie odpowiemy na pytania: jaki cel ma zostać osiągnięty, jakie informacje o sytuacji biznesowej są znane i do jakiej grupy docelowej rozwiązanie ma zostać przekazane',
          'Analizujemy sytuacje i środowisko, w którym mamy wdrożyć nasze rozwiązanie. Sprawdzamy wszystkie procesy pod kątem integracji i zgodności z koncepcją usprawnienia',
        ],
        en: [''],
      },
      number: 1,
    },
    {
      title: {
        pl: ['Strategia'],
        en: ['Strategy'],
      },
      subtitle: {
        pl: 'Etap 2',
        en: 'Stage 2',
      },
      unorderedList: {
        pl: [
          'Po przeprowadzeniu analizy dobieramy odpowiednią strategię biznesową dla Twojego rozwiązania. Bierzemy pod uwagę wszystkie możliwe czynniki, które wpływają zarówno pozytywnie, jak i negatywnie na wybrany przez nas model',
          'Przygotowujemy dokumentację w oparciu o standardy UML: modele, diagramy i opisy założeń, które umożliwią Ci poznanie szczegółów działania systemu',
          'Definiujemy harmonogram pracy, na podstawie którego oszacujemy koszty każdego z etapów oraz poziomy funkcjonalności poszczególnych modułów',
        ],
        en: [
          'Poznajemy Twój biznes i dążymy do zrozumienia jego procesów. Myślimy o możliwościach rozwoju i wdrożenia usprawnień',
          'Analizujemy sytuacje i środowisko, w którym mamy wdrożyć nasze rozwiązanie. Sprawdzamy wszystkie procesy pod kątem integracji i zgodności z koncepcją usprawnienia',
          'Po przeprowadzeniu analizy przygotowujemy specyfikację funkcjonalną oraz wstępny harmonogram pracy. Na podstawie dokumentacji możemy oszacować koszty każdego z etapów oraz poziom funkcjonalności',
        ],
      },
      number: 2,
    },
    {
      title: {
        pl: ['Projektowanie'],
        en: ['Designing'],
      },
      subtitle: {
        pl: 'Etap 3',
        en: 'Stage 3',
      },
      unorderedList: {
        pl: [
          'Jeżeli produkt wymaga projektu graficznego, przygotowujemy zbiór makiet, grafik i schematów, które pozwolą zwizualizować procesy w systemie',
          'Projektujemy interaktywny prototyp produktu oraz interfejsy użytkownika (UI), które pozwolą doświadczyć częściowego działania systemy (UX). Dzięki temu już na etapie projektowana możemy wprowadzić dodatkowe udogodnienia i zniwelować niepożądane scenariusze',
          'Określamy technologie i narzędzia, które wykorzystamy do wdrożenia wszystkich funkcjonalności',
        ],
        en: [
          'Jeżeli produkt wymaga projektu graficznego, przygotowujemy zbiór makiet, grafik i schematów, które pozwolą zwizualizować system',
          'Projektujemy interaktywny prototyp produktu, oraz interfejsy (UI), które pozwolą zarówno nam, jak i klientowi doświadczyć częściowego działania systemy (UX)',
          'Określamy technologie i narzędzia , które wykorzystamy do wdrożenia wszystkich funkcjonalności',
        ],
      },
      number: 3,
    },
    {
      title: {
        pl: ['Implementacja'],
        en: ['Implementation'],
      },
      subtitle: {
        pl: 'Etap 3',
        en: 'Stage 3',
      },
      unorderedList: {
        pl: [
          'Projektujemy architekturę systemy tak, aby umożliwiła ona tworzenie skalowanego produktu, który może być modyfikowany w przyszłości',
          'Kodujemy składowe systemu w postaci programów, podprogramów, komponentów, modułów, aż po interfejsy API',
          'Przeprowadzamy testy jednostkowe poszczególnych funkcjonalności, testy integracyjne złożonych komponentów i systemów oraz testy funkcjonalne w środowisku docelowym',
        ],
        en: [
          'Projektujemy architekturę systemy tak, aby umożliwiła ona tworzenie skalowanego produktu, który może być modyfikowany w przyszłości',
          'Kodujemy składowe systemu w postaci programów, podprogramów, komponentów, modułów, aż po interfejsy API',
          'Przeprowadzamy testy jednostkowe poszczególnych funkcjonalności, testy integracyjne złożonych komponentów i systemów oraz testy funkcjonalne w środowisku docelowym',
        ],
      },
      number: 3,
    },
    {
      title: {
        pl: ['Wdrożenie'],
        en: ['Deployment'],
      },
      subtitle: {
        pl: 'Etap Końcowy',
        en: 'Final Stage',
      },
      unorderedList: {
        pl: [
          'Ostatni etap w cyklu "życia" Twojego oprogramowania',
          'Podsumowujemy naszą pracę w oparciu założenia w specyfikacji produktu i konsultacje z grupą docelową',
          'Gotowy produkt jest eksploatowany i w razie konieczności ewoluowany',
        ],
        en: [
          'Podsumowujemy naszą pracę w oparciu założenia w specyfikacji produktu i konsultacje z klientem',
          'Gotowy produkt jest eksploatowany i w razie potrzeby ewoluowany',
        ],
      },
      icon: 'check',
    },
  ],
  button: {
    text: {
      pl: 'WYCEŃ PROJEKT',
      en: 'ESTIMATE PROJECT',
    },
    link: {
      internal: true,
      to: slugs.contact,
    },
  },
}

const coopSection = {
  name: {
    pl: 'Współpraca',
    en: 'Cooperation',
  },
  hideNameOnPage: true,
  description: {},
  sections: [coopModelsSection, coopStagesSection],
}

const techSection = {
  name: {
    pl: 'Tech Stack',
    en: 'Tech Stack',
  },
  description: {
    pl: `Zapoznaj się z naszym "Stack'iem" Technologicznym. Są to języki programowania, frameworki, narzędzia i biblioteki, z których korzystamy w codziennej pracy. Nasza lista technologi jest stale aktualizowana. Cenimy sprawdzone metody, ale nie wachamy się przejścia na nowe`,
    en:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
  },
  hash: hashes.techstack,
  techs: [
    {
      id: 1,
      name: 'React',
      icon: 'reactjs',
      description: {
        pl:
          'Naszym zdaniem bezkonkurencyjny król rozszerzeń języka JavaScript, który wykorzystujemy do implementacji dynamicznych interfejsów użytkownika. Aplikacje internetowe napisane w środowisku React cechują się niezwykłą prędkością, dzięki nowatorskiemu podejściu do aktualizacji DOM, który definiuje strukturę wyświetlanej strony w przeglądarce',
        en:
          'Poznajemy Twój biznes i dążymy do zrozumienia jego procesów. Myślimy o możliwościach rozwoju i wdrożenia usprawnień',
      },
    },
    {
      id: 2,
      name: 'GraphQL',
      icon: 'graphql',
      description: {
        pl:
          'Nadeszła rewolucja - era GraphQL. Język, który w odróżnieniu od standardowych REST API, pozwala na definiowanie własnych zapytań i umożliwia pełną dowolność manipulacji danymi. Architektura GraphQL, jak sama nazwa wskazuje, zamiast modelu drzewa, tworzy z kolekcji model w postaci grafu, dzięki czemu w łatwiejszy sposób możemy sięgać po zawile powiązane i poprzeplatane ze sobą informacje',
        en:
          'Poznajemy Twój biznes i dążymy do zrozumienia jego procesów. Myślimy o możliwościach rozwoju i wdrożenia usprawnień',
      },
    },
    {
      id: 3,
      name: 'Apollo',
      icon: 'apollo',
      description: {
        pl:
          'Apollo dzieli się na 2 ekosystemu - po stronie użytkownika oraz po stronie serwera. Umożliwia on pełną synchronizację pomiędzy interfejsem React, architekturą danych GraphQL oraz aplikacją serwerową. Posiada zestaw optymalizacji zarządzania pamięcią, dzięki czemu, ogranicza ilość zapytań oraz przyśpiesza działania aplikacji. Narzędzie to zasadniczo ułatwia pracę deweloperom',
        en:
          'Poznajemy Twój biznes i dążymy do zrozumienia jego procesów. Myślimy o możliwościach rozwoju i wdrożenia usprawnień',
      },
    },
    {
      id: 4,
      name: 'Gatsby',
      icon: 'gatsbyjs',
      description: {
        pl:
          'Framework Gatsby umożliwiający zbudowanie statycznej wersji aplikacji internetowej. Jego cel jest jasny i jednoznaczny - szybkość i wydajność. Strony napisane w Gatsby zapewniają pełne indeksowanie w wyszukiwarkach, pomimo oparcia o dynamiczne skrypty React, któree kształtują witrynę i wypełniają ją treścią dopiero po załadowaniu aplikacji w przeglądarce',
        en:
          'Poznajemy Twój biznes i dążymy do zrozumienia jego procesów. Myślimy o możliwościach rozwoju i wdrożenia usprawnień',
      },
    },
    {
      id: 5,
      name: 'NodeJS',
      icon: 'nodejs',
      description: {
        pl:
          'Nasz stack technologiczny oparty jest w większej mierze o JavaScript, dlatego nie jest żadną niespodzianką, że pojawia się on na naszej liście. Środowisko Node.js umożliwia programowanie aplikacji sieciowych w dobrze nam znanym języku, a stałe aktualizacje oraz liczne community sprawiają, że platforma stała się numerem jeden w świecie backendu',
        en:
          'Poznajemy Twój biznes i dążymy do zrozumienia jego procesów. Myślimy o możliwościach rozwoju i wdrożenia usprawnień',
      },
    },
    {
      id: 6,
      name: 'WordPress',
      icon: 'wordpress',
      description: {
        pl:
          'Niesławne szablony Wordpress goszczą również naszą listę technologii. Wykorzystujemy je do budowy prostych stron WWW, które często są aktualizowane przez samych klientów. Liczne motywy i wtyczki pozwalają na wygodne dostosowanie stron i ich treści',
        en:
          'Poznajemy Twój biznes i dążymy do zrozumienia jego procesów. Myślimy o możliwościach rozwoju i wdrożenia usprawnień',
      },
    },
    {
      id: 7,
      name: 'Adobe XD',
      icon: 'adobexd',
      description: {
        pl:
          'XD może kojarzy się zabawnie, ale jest to znakomite narzędzie graficzne, służące do projektowania i prototypowiania każdego rodzaju aplikacji, dedykowanego na dowolne urządzenie. Produkt Adobe szczególnie rozwiną się jesienią roku 2020 poprzez szereg aktualizacji, które wprowadziły tzw. "system stanów komponentów". Od teraz każdy element graficzny może zawierać wiele swoich alternatywnych wersji, co zrewolucjonizowało to, w jaki sposób projektuje się nowoczesny design',
        en:
          'Poznajemy Twój biznes i dążymy do zrozumienia jego procesów. Myślimy o możliwościach rozwoju i wdrożenia usprawnień',
      },
    },
  ],
}

const careerSection = {
  name: {
    pl: 'Kariera',
    en: 'Career',
  },
  title: {
    pl: ['Dołącz do zespołu! AppClever', { text: 'rekrutuje', special: true }],
    en: [
      'Join the Clever team! AppClever is',
      { text: 'recruiting', special: true },
    ],
  },
  description: {
    pl:
      'Jesteś programistą, projektantem, architektem systemów lub zajmujesz się zarządzaniem procesów inżynierii oprogramowania i traktujesz swoją profesję nie tylko jako praca, ale także jako pasja? Szukasz nowych perspektyw kariery w branży IT? Wypełnij poniższy formularz, opisując, czym się zajmujesz i jakie masz doświadczenie. Odpowiemy mailowo lub telefonicznie, opisując dalsze etapy rekrutacji najszybciej jak to możliwe',
    en:
      'Lorem ipsum… Odpiszemy jak tylko to możliwe. Ustalimy wówczas szczegóły dalszej współpracy',
  },
  hash: hashes.career,
  button: {
    text: {
      pl: 'WYŚLIJ',
      en: 'SEND',
    },
  },
}

const valuationNeededSection = {
  title: {
    pl: [
      'Potrzebujesz',
      { text: 'wyceny', special: true },
      'swojego projektu?',
    ],
    en: ['Project', { text: 'estimation', special: true }, 'needed?'],
  },
  button: {
    text: {
      pl: 'WYPEŁNIJ FORMULARZ WYCENY',
      en: 'FILL IN THE VALUATION FORM',
    },
    internal: true,
    to: slugs.valuation,
  },
}

const policyPage = {
  name: {
    pl: 'Polityka Prywatności',
    en: 'Privacy Policy',
  },
  p1: [
    '1.1. Niniejsza polityka prywatności Serwisu Internetowego ma charakter informacyjny, co oznacza że nie jest ona źródłem obowiązków dla Usługobiorców lub Klientów Serwisu Internetowego. Polityka prywatności zawiera przede wszystkim zasady dotyczące przetwarzania danych osobowych przez Administratora w Serwisie Internetowym, w tym podstawy, cele i zakres przetwarzania danych osobowych oraz prawa osób, których dane dotyczą, a także informacje w zakresie stosowania w Serwisie Internetowym plików cookies oraz narzędzi analitycznych.',
    '1.2. Administratorem danych osobowych zbieranych za pośrednictwem Serwisu Internetowego jest AppClever Spółka z ograniczoną odpowiedzialnością  z siedzibą w Gdańsku, 80-530, ul. Sucha 15 pII, wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Gdańsk-Północ w Gdańsku, VII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem: 0000754291, NIP:  5842793647, REGON: 386118455 , adres poczty elektronicznej: biuro@appclever.pl - zwany dalej „Administratorem” i będący jednocześnie Usługodawcą Serwisu Internetowego i Sprzedawcą.',
    '1.3. Dane osobowe w Serwisie Internetowym przetwarzane są przez Administratora zgodnie z obowiązującymi przepisami prawa, w szczególności zgodnie z rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) - zwane dalej „RODO” lub „Rozporządzenie RODO”, a także przepisami ustawy o ochronie danych osobowych z dnia 10 maja 2018 r. (Dz.U.2018.1000). ',
    '1.4. Korzystanie ze Serwisu Internetowego, w tym dokonywanie zakupów jest dobrowolne. Podobnie związane z tym podanie danych osobowych przez korzystającego ze Serwisu Internetowego Usługobiorcę lub Klienta jest dobrowolne, z zastrzeżeniem dwóch wyjątków: (1) zawieranie umów z Administratorem - niepodanie w przypadkach i w zakresie wskazanym na stronie Serwisu Internetowego oraz w Regulaminie Serwisu Internetowego i niniejszej polityce prywatności danych osobowych niezbędnych do zawarcia i wykonania Umowy Sprzedaży lub umowy o świadczenie Usługi Elektronicznej z Administratorem skutkuje brakiem możliwości zawarcia tejże umowy. Podanie danych osobowych jest w takim wypadku wymogiem umownym i jeżeli osoba, które dane dotyczą chce zawrzeć daną umowę z Administratorem, to jest zobowiązana do podania wymaganych danych. Każdorazowo zakres danych wymaganych do zawarcia umowy wskazany jest uprzednio na stronie Serwisu Internetowego oraz w Regulaminie Serwisu Internetowego; (2) obowiązki ustawowe Administratora - podanie danych osobowych jest wymogiem ustawowym wynikającym z powszechnie obowiązujących przepisów prawa nakładających na Administratora obowiązek przetwarzania danych osobowych (np. przetwarzanie danych w celu prowadzenia ksiąg podatkowych lub rachunkowych) i brak ich podania uniemożliwi Administratorowi wykonanie tychże obowiązków.',
    '1.5. Administrator dokłada szczególnej staranności w celu ochrony interesów osób, których przetwarzane przez niego dane osobowe dotyczą, a w szczególności jest odpowiedzialny i zapewnia, że zbierane przez niego dane są: (1) przetwarzane zgodnie z prawem; (2) zbierane dla oznaczonych, zgodnych z prawem celów i niepoddawane dalszemu przetwarzaniu niezgodnemu z tymi celami; (3) merytorycznie poprawne i adekwatne w stosunku do celów, w jakich są przetwarzane; (4) przechowywane w postaci umożliwiającej identyfikację osób, których dotyczą, nie dłużej niż jest to niezbędne do osiągnięcia celu przetwarzania oraz (5) przetwarzane w sposób zapewniający odpowiednie bezpieczeństwo danych osobowych, w tym ochronę przed niedozwolonym lub niezgodnym z prawem przetwarzaniem oraz przypadkową utratą, zniszczeniem lub uszkodzeniem, za pomocą odpowiednich środków technicznych lub organizacyjnych.',
    '1.6. Uwzględniając charakter, zakres, kontekst i cele przetwarzania oraz ryzyko naruszenia praw lub wolności osób fizycznych o różnym prawdopodobieństwie i wadze zagrożenia, Administrator wdraża odpowiednie środki techniczne i organizacyjne, aby przetwarzanie odbywało się zgodnie z niniejszym rozporządzeniem i aby móc to wykazać. Środki te są w razie potrzeby poddawane przeglądom i uaktualniane. Administrator stosuje środki techniczne zapobiegające pozyskiwaniu i modyfikowaniu przez osoby nieuprawnione, danych osobowych przesyłanych drogą elektroniczną.',
    '1.7. Wszelkie słowa, wyrażenia i akronimy występujące w niniejszej polityce prywatności i rozpoczynające się dużą literą (np. Sprzedawca, Serwis Internetowy, Usługa Elektroniczna) należy rozumieć zgodnie z ich definicją zawartą w Regulaminie Serwisu Internetowego dostępnym na stronach Serwisu Internetowego.',
  ],
  p2: [
    '2. PODSTAWY PRZETWARZANIA DANYCH',
    '2.1. Administrator uprawniony jest do przetwarzania danych osobowych w przypadkach, gdy – i w takim zakresie, w jakim – spełniony jest co najmniej jeden z poniższych warunków: (1) osoba, której dane dotyczą wyraziła zgodę na przetwarzanie swoich danych osobowych w jednym lub większej liczbie określonych celów; (2) przetwarzanie jest niezbędne do wykonania umowy, której stroną jest osoba, której dane dotyczą, lub do podjęcia działań na żądanie osoby, której dane dotyczą, przed zawarciem umowy; (3) przetwarzanie jest niezbędne do wypełnienia obowiązku prawnego ciążącego na Administratorze; lub (4) przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów realizowanych przez Administratora lub przez stronę trzecią, z wyjątkiem sytuacji, w których nadrzędny charakter wobec tych interesów mają interesy lub podstawowe prawa i wolności osoby, której dane dotyczą, wymagające ochrony danych osobowych, w szczególności gdy osoba, której dane dotyczą, jest dzieckiem.',
    '2.2. Przetwarzanie danych osobowych przez Administratora wymaga każdorazowo zaistnienia co najmniej jednej z podstaw wskazanych w pkt. 2.1 polityki prywatności. Konkretne podstawy przetwarzania danych osobowych Usługobiorców i Klientów Serwisu Internetowego przez Administratora są wskazane w kolejnym punkcie polityki prywatności – w odniesieniu do danego celu przetwarzania danych osobowych przez Administratora.',
  ],
  p3: [
    '3. CEL, PODSTAWA, OKRES I ZAKRES PRZETWARZANIA DANYCH W SERWISIE INTERNETOWYM',
    '3.1.Każdorazowo cel, podstawa, okres i zakres oraz odbiorcy danych osobowych przetwarzanych przez Administratora wynika z działań podejmowanych przez danego Usługobiorcę lub Klienta w Serwisie Internetowym. Przykładowo jeżeli Klient decyduje się na dokonanie zakupów w Serwisie Internetowym i wybierze odbiór osobisty zakupionego Produktu zamiast przesyłki kurierskiej, to jego dane osobowe będą przetwarzane w celu wykonania zawartej Umowy Sprzedaży, ale nie będą już udostępniane przewoźnikowi realizującemu przesyłki na zlecenie Administratora.',
    '3.2. Administrator może przetwarzać dane osobowe w Serwisie Internetowym w następujących celach, na następujących podstawach, w okresach oraz w następującym zakresie:',
  ],
  tab: [
    {
      col: {
        title: 'Cel przetwarzania danych',
        rows: [
          'Wykonanie Umowy Sprzedaży lub umowy o świadczenie Usługi Elektronicznej lub podjęcie działań na żądanie osoby, której dane dotyczą, przed zawarciem w/w umów',
          'Marketing bezpośredni',
          'Marketing',

          'Wyrażenie przez Klienta opinii o zawartej Umowie Sprzedaży',
          'Prowadzenie ksiąg rachunkowych',

          'Ustalenie, dochodzenie lub obrona roszczeń jakie może podnosić Administrator lub jakie mogą być podnoszone wobec Administratora',
        ],
      },
    },
    {
      col: {
        title: 'Podstawa prawna przetwarzania i okres przechowywania danych',
        rows: [
          'Artykuł 6 ust. 1 lit. b) Rozporządzenia RODO (wykonanie umowy)Dane są przechowywane przez okres niezbędny do wykonania, rozwiązania lub wygaśnięcia w inny sposób zawartej umowy.',
          'Artykuł 6 ust. 1 lit. f) Rozporządzenia RODO (prawnie uzasadniony interes administratora)Dane są przechowywane przez okres istnienia prawnie uzasadnionego interesu realizowanego przez Administratora, nie dłużej jednak niż przez okres przedawnienia roszczeń w stosunku do osoby, której dane dotyczą, z tytułu prowadzonej przez Administratora działalności gospodarczej. Okres przedawnienia określają przepisy prawa, w szczególności kodeksu cywilnego (podstawowy termin przedawnienia dla roszczeń związanych z prowadzeniem działalności gospodarczej wynosi trzy lata, a dla umowy sprzedaży dwa lata).Administrator nie może przetwarzać danych w celu marketingu bezpośredniego w przypadku wyrażenia skutecznego sprzeciwu w tym zakresie przez osobę, której dane dotyczą.',
          'Artykuł 6 ust. 1 lit. a) Rozporządzenia RODO (zgoda)Dane przechowywane są do momentu wycofania zgody przez osobę, której dane dotyczą na dalsze przetwarzanie jej danych w tym celu.',
          'Artykuł 6 ust. 1 lit. a) Rozporządzenia RODODane przechowywane są do momentu wycofania zgody przez osobę, której dane dotyczą na dalsze przetwarzanie jej danych w tym celu.',
          'Artykuł 6 ust. 1 lit. c) Rozporządzenia RODO w zw. z art. 86 § 1 Ordynacji podatkowej tj. z dnia 17 stycznia 2017 r. (Dz.U. z 2017 r. poz. 201)Dane są przechowywane przez okres wymagany przepisami prawa nakazującymi Administratorowi przechowywanie ksiąg podatkowych (do czasu upływu okresu przedawnienia zobowiązania podatkowego, chyba że ustawy podatkowe stanowią inaczej).',
          'Artykuł 6 ust. 1 lit. f) Rozporządzenia RODOD dane są przechowywane przez okres istnienia prawnie uzasadnionego interesu realizowanego przez Administratora, nie dłużej jednak niż przez okres przedawnienia roszczeń w stosunku do osoby, której dane dotyczą, z tytułu prowadzonej przez Administratora działalności gospodarczej. Okres przedawnienia określają przepisy prawa, w szczególności kodeksu cywilnego (podstawowy termin przedawnienia dla roszczeń związanych z prowadzeniem działalności gospodarczej wynosi trzy lata, a dla umowy sprzedaży dwa lata).',
        ],
      },
    },
    {
      col: {
        title: 'Zakres przetwarzanych danych',
        rows: [
          'Zakres maksymalny: imię i nazwisko; adres poczty elektronicznej; numer telefonu kontaktowego; adres dostawy (ulica, numer domu, numer lokalu, kod pocztowy, miejscowość, kraj), adres zamieszkania/prowadzenia działalności/siedziby (jeżeli jest inny niż adres dostawy).W wypadku Usługobiorców lub Klientów nie będących konsumentami Administrator może przetwarzać dodatkowo nazwę firmy oraz numer identyfikacji podatkowej (NIP) Usługobiorcy lub Klienta.Podany zakres jest maksymalny – w przypadku np. odbioru osobistego nie jest konieczne podanie adresu dostawy.',
          'Adres poczty elektronicznej',
          'Imię, adres poczty elektronicznej',
          'Imię i nazwisko; numer telefonu kontaktowego; adres poczty elektronicznej; W wypadku Usługobiorców lub Klientów nie będących konsumentami Administrator może przetwarzać dodatkowo nazwę firmy oraz numer identyfikacji podatkowej (NIP) Usługobiorcy lub Klienta.',
        ],
      },
    },
  ],
  p4: [
    '4. POSTANOWIENIA OGÓLNEODBIORCY DANYCH W SERWISIE INTERNETOWYM',
    '4.1. Dla prawidłowego funkcjonowania Serwisu Internetowego, w tym dla realizacji zawieranych Umów Sprzedaży konieczne jest korzystanie przez Administratora z usług podmiotów zewnętrznych (takich jak np. dostawca oprogramowania, kurier, czy podmiot obsługujący płatności). Administrator korzysta wyłącznie z usług takich podmiotów przetwarzających, którzy zapewniają wystarczające gwarancje wdrożenia odpowiednich środków technicznych i organizacyjnych, tak by przetwarzanie spełniało wymogi Rozporządzenia RODO i chroniło prawa osób, których dane dotyczą. ',
    '4.2. Przekazanie danych przez Administratora nie następuje w każdym wypadku i nie do wszystkich wskazanych w polityce prywatności odbiorców lub kategorii odbiorców – Administrator przekazuje dane wyłącznie wtedy, gdy jest to niezbędne do realizacji danego celu przetwarzania danych osobowych i tylko w zakresie niezbędnym do jego zrealizowania. Przykładowo, jeżeli Klient korzysta z odbioru osobistego, to jego dane nie będą przekazywane przewoźnikowi współpracującemu z Administratorem. ',
    '4.3. Dane osobowe Usługobiorców i Klientów Serwisu Internetowego mogą być przekazywane następującym odbiorcom lub kategoriom odbiorców: ',
    '4.3.1. przewoźnicy / spedytorzy / brokerzy kurierscy - w przypadku Klienta, który korzysta w Serwisie Internetowym ze sposobu dostawy Produktu przesyłką pocztową lub przesyłką kurierską, Administrator udostępnia zebrane dane osobowe Klienta wybranemu przewoźnikowi, spedytorowi lub pośrednikowi realizującemu przesyłki na zlecenie Administratora w zakresie niezbędnym do zrealizowania dostawy Produktu Klientowi. ',
    '4.3.2. podmioty obsługujące płatności elektroniczne lub kartą płatniczą - w przypadku Klienta, który korzysta w Serwisie Internetowym ze sposobu płatności elektronicznych lub kartą płatniczą Administrator udostępnia zebrane dane osobowe Klienta wybranemu podmiotowi obsługującemu powyższe płatności w Serwisie Internetowym na zlecenie Administratora w zakresie niezbędnym do obsługi płatności realizowanej przez Klienta. ',
    '4.3.3. dostawcy systemu ankiet opiniujących - w przypadku Klienta, który zgodził się na wyrażenie opinii o zawartej Umowie Sprzedaży, Administrator udostępnia zebrane dane osobowe Klienta wybranemu podmiotowi dostarczającemu system ankiet opiniujących zawarte Umowy Sprzedaży w Serwisie Internetowym na zlecenie Administratora w zakresie niezbędnym do wyrażenia przez Klienta opinii za pomocą systemu ankiet opiniujących. ',
    '4.3.4. dostawcy usług zaopatrujący Administratora w rozwiązania techniczne, informatyczne oraz organizacyjne, umożliwiające Administratorowi prowadzenie działalności gospodarczej, w tym Serwisu Internetowego i świadczonych za jego pośrednictwem Usług Elektronicznych (w szczególności dostawcy oprogramowania komputerowego do prowadzenia Serwisu Internetowego, dostawcy poczty elektronicznej i hostingu oraz dostawcy oprogramowania do zarządzania firmą i udzielania pomocy technicznej Administratorowi) - Administrator udostępnia zebrane dane osobowe Klienta wybranemu dostawcy działającemu na jego zlecenie jedynie w przypadku oraz w zakresie niezbędnym do zrealizowania danego celu przetwarzania danych zgodnego z niniejszą polityką prywatności. ',
    '4.3.5. dostawcy usług księgowych, prawnych i doradczych zapewniający Administratorowi wsparcie księgowe, prawne lub doradcze (w szczególności biuro księgowe, kancelaria prawna lub firma windykacyjna) - Administrator udostępnia zebrane dane osobowe Klienta wybranemu dostawcy działającemu na jego zlecenie jedynie w przypadku oraz w zakresie niezbędnym do zrealizowania danego celu przetwarzania danych zgodnego z niniejszą polityką prywatności. ',
  ],
  p5: [
    '5. PROFILOWANIE W SERWISIE INTERNETOWYM',
    '5.1. Rozporządzenie RODO nakłada na Administratora obowiązek informowania o zautomatyzowanym podejmowaniu decyzji, w tym o profilowaniu, o którym mowa w art. 22 ust. 1 i 4 Rozporządzenia RODO, oraz – przynajmniej w tych przypadkach – istotne informacje o zasadach ich podejmowania, a także o znaczeniu i przewidywanych konsekwencjach takiego przetwarzania dla osoby, której dane dotyczą. Mając to na uwadze Administrator podaje w tym punkcie polityki prywatności informacje dotyczące możliwego profilowania. ',
    '5.2. Administrator może korzystać w Serwisie Internetowym z profilowania do celów marketingu bezpośredniego, ale decyzje podejmowane na jego podstawie przez Administratora nie dotyczą zawarcia lub odmowy zawarcia Umowy Sprzedaży, czy też możliwości korzystania z Usług Elektronicznych w Serwisie Internetowym. Efektem korzystania z profilowania w Serwisie Internetowym może być np. przyznanie danej osobie rabatu, przesłanie jej kodu rabatowego, przypomnienie o niedokończonych zakupach, przesłanie propozycji Produktu, który może odpowiadać zainteresowaniom lub preferencjom danej osoby lub też zaproponowanie lepszych warunków w porównaniu do standardowej oferty Serwisu Internetowego. Mimo profilowania to dana osoba podejmuje swobodnie decyzję, czy będzie chciała skorzystać z otrzymanego w ten sposób rabatu, czy też lepszych warunków i dokonać zakupu w Serwisie Internetowym. ',
    '5.3. Profilowanie w Serwisie Internetowym polega na automatycznej analizie lub prognozie zachowania danej osoby na stronie Serwisu Internetowego np. poprzez dodanie konkretnego Produktu do koszyka, przeglądanie strony konkretnego Produktu w Serwisie Internetowym, czy też poprzez analizę dotychczasowej historii dokonanych zakupów w Serwisie Internetowym. Warunkiem takiego profilowania jest posiadanie przez Administratora danych osobowych danej osoby, aby móc jej następnie przesłać np. kod rabatowy. ',
    '5.4. Osoba, której dane dotyczą, ma prawo do tego, by nie podlegać decyzji, która opiera się wyłącznie na zautomatyzowanym przetwarzaniu, w tym profilowaniu, i wywołuje wobec tej osoby skutki prawne lub w podobny sposób istotnie na nią wpływa. ',
  ],
  p6: [
    '6. PRAWA OSOBY, KTÓREJ DANE DOTYCZĄ',
    '6.1. Prawo dostępu, sprostowania, ograniczenia, usunięcia lub przenoszenia - osoba, której dane dotyczą, ma prawo żądania od Administratora dostępu do swoich danych osobowych, ich sprostowania, usunięcia („prawo do bycia zapomnianym”) lub ograniczenia przetwarzania oraz ma prawo do wniesienia sprzeciwu wobec przetwarzania, a także ma prawo do przenoszenia swoich danych. Szczegółowe warunki wykonywania wskazanych wyżej praw wskazane są w art. 15-21 Rozporządzenia RODO.',
    '6.2. Prawo do cofnięcia zgody w dowolnym momencie – osoba, której dane przetwarzane są przez Administratora na podstawie wyrażonej zgody (na podstawie art. 6 ust. 1 lit. a) lub art. 9 ust. 2 lit. a) Rozporządzenia RODO), to ma ona prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.',
    '6.3. Prawo wniesienia skargi do organu nadzorczego – osoba, której dane przetwarzane są przez Administratora, ma prawo wniesienia skargi do organu nadzorczego w sposób i trybie określonym w przepisach Rozporządzenia RODO oraz prawa polskiego, w szczególności ustawy o ochronie danych osobowych. Organem nadzorczym w Polsce jest Prezes Urzędu Ochrony Danych Osobowych.',
    '6.4. Prawo do sprzeciwu - osoba, której dane dotyczą, ma prawo w dowolnym momencie wnieść sprzeciw – z przyczyn związanych z jej szczególną sytuacją – wobec przetwarzania dotyczących jej danych osobowych opartego na art. 6 ust. 1 lit. e) (interes lub zadania publiczne) lub f) (prawnie uzasadniony interes administratora), w tym profilowania na podstawie tych przepisów. Administratorowi w takim przypadku nie wolno już przetwarzać tych danych osobowych, chyba że wykaże on istnienie ważnych prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec interesów, praw i wolności osoby, której dane dotyczą, lub podstaw do ustalenia, dochodzenia lub obrony roszczeń.',
    '6.5. Prawo do sprzeciwu dot. marketingu bezpośredniego - jeżeli dane osobowe są przetwarzane na potrzeby marketingu bezpośredniego, osoba, której dane dotyczą, ma prawo w dowolnym momencie wnieść sprzeciw wobec przetwarzania dotyczących jej danych osobowych na potrzeby takiego marketingu, w tym profilowania, w zakresie, w jakim przetwarzanie jest związane z takim marketingiem bezpośrednim.',
    '6.6. W celu realizacji uprawnień, o których mowa w niniejszym punkcie polityki prywatności można kontaktować się z Administratorem poprzez przesłanie stosownej wiadomości pisemnie lub pocztą elektroniczną na adres Administratora wskazany na wstępie polityki prywatności lub korzystając z formularza kontaktowego dostępnego na stronie Serwisu Internetowego.',
  ],
  p7: [
    '7. COOKIES W Serwisie INTERNETOWYM, DANE EKSPLOATACYJNE I ANALITYKA',
    '7.1. Pliki Cookies (ciasteczka) są to niewielkie informacje tekstowe w postaci plików tekstowych, wysyłane przez serwer i zapisywane po stronie osoby odwiedzającej stronę Serwisu Internetowego (np. na dysku twardym komputera, laptopa, czy też na karcie pamięci smartfona – w zależności z jakiego urządzenia korzysta odwiedzający nasz Sklep Internetowy). Szczegółowe informacje dot. plików Cookies, a także historię ich powstania można znaleźć m.in. tutaj: https://pl.wikipedia.org/wiki/Ciasteczko.',
    '7.2. Administrator może przetwarzać dane zawarte w plikach Cookies podczas korzystania przez odwiedzających ze strony Serwisu Internetowego w następujących celach:',
    '7.2.1. identyfikacji Usługobiorców jako zalogowanych w Serwisie Internetowym i pokazywania, że są zalogowani;',
    '7.2.2. zapamiętywania Produktów dodanych do koszyka w celu złożenia Zamówienia;',
    '7.2.3. zapamiętywania danych z wypełnianych Formularzy Zamówienia, ankiet lub danych logowania do Serwisu Internetowego;',
    '7.2.4. dostosowywania zawartości strony Serwisu Internetowego do indywidualnych preferencji Usługobiorcy (np. dotyczących kolorów, rozmiaru czcionki, układu strony) oraz optymalizacji korzystania ze stron Serwisu Internetowego;',
    '7.2.5. prowadzenia anonimowych statystyk przedstawiających sposób korzystania ze strony Serwisu Internetowego;',
    '7.2.6. remarketingu, to jest badania cech zachowania odwiedzających Sklep Internetowy poprzez anonimową analizę ich działań (np. powtarzające się wizyty na określonych stronach, słowa kluczowe itp.) w celu stworzenia ich profilu i dostarczenia im reklam dopasowanych do ich przewidywanych zainteresowań, także wtedy kiedy odwiedzają oni inne strony internetowe w sieci reklamowej firmy Google Inc. oraz Facebook Ireland Ltd.;',
    '7.3. Standardowo większość przeglądarek internetowych dostępnych na rynku domyślnie akceptuje zapisywanie plików Cookies. Każdy ma możliwość określenia warunków korzystania z plików Cookies za pomocą ustawień własnej przeglądarki internetowej. Oznacza to, że można np. częściowo ograniczyć (np. czasowo) lub całkowicie wyłączyć możliwość zapisywania plików Cookies – w tym ostatnim wypadku jednak może to mieć wpływ na niektóre funkcjonalności Serwisu Internetowego (przykładowo niemożliwym może okazać się przejście ścieżki Zamówienia poprzez Formularz Zamówienia z uwagi na niezapamiętywanie Produktów w koszyku podczas kolejnych kroków składania Zamówienia).',
    '7.4. Ustawienia przeglądarki internetowej w zakresie plików Cookies są istotne z punktu widzenia zgody na korzystanie z plików Cookies przez nasz Sklep Internetowy – zgodnie z przepisami taka zgoda może być również wyrażona poprzez ustawienia przeglądarki internetowej. W braku wyrażenia takiej zgody należy odpowiednio zmienić ustawienia przeglądarki internetowej w zakresie plików Cookies.',
    "7.5. Szczegółowe informacje na temat zmiany ustawień dotyczących plików Cookies oraz ich samodzielnego usuwania w najpopularniejszych przeglądarkach internetowych dostępne są w dziale pomocy przeglądarki internetowej oraz na poniższych stronach (wystarczy kliknąć w dany link): <br>- <a href='https://support.google.com/chrome/answer/95647?hl=pl'>w przeglądarce Chrome</a> <br>  - <a href='https://support.mozilla.org/pl/kb/wzmocniona-ochrona-przed-sledzeniem-firefox-desktop?redirectslug=W%C5%82%C4%85czanie+i+wy%C5%82%C4%85czanie+obs%C5%82ugi+ciasteczek&redirectlocale=pl'>w przeglądarce Firefox</a> <br> - <a href='https://support.microsoft.com/pl-pl/help/4468242/microsoft-edge-browsing-data-and-privacy'>w przeglądarce Internet Explorer</a> <br>- <a href='https://help.opera.com/pl/latest/web-preferences/#cookies'>w przeglądarce Opera</a> <br>- <a href='https://support.apple.com/pl-pl/guide/safari/sfri11471/mac'>w przeglądarce Safari</a>  <br>- <a href='https://support.microsoft.com/pl-pl/help/4468242/microsoft-edge-browsing-data-and-privacy'>w przeglądarce Microsoft Edge</a> <br>",
    '7.6. Administrator może korzystać w Serwisie Internetowym z usług Google Analytics, Universal Analytics dostarczanych przez firmę Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Usługi te pomagają Administratorowi analizować ruch w Serwisie Internetowym. Gromadzone dane przetwarzane są w ramach powyższych usług w sposób zanonimizowany (są to tzw. dane eksploatacyjne, które uniemożliwiają identyfikację osoby) do generowania statystyk pomocnych w administrowaniu Serwisie Internetowym. Dane te mają charakter zbiorczy i anonimowy, tj. nie zawierają cech identyfikujących (danych osobowych) osoby odwiedzające stronę Serwisu Internetowego. Administrator korzystając z powyższych usług w Serwisie Internetowym gromadzi takie dane jak źródła i medium pozyskania odwiedzjących Sklep Internetowy oraz sposób ich zachowania na stronie Serwisu Internetowego, informacje na temat urządzeń i przeglądarek z których odwiedzają stronę, IP oraz domenę, dane geograficzne oraz dane demograficzne (wiek, płeć) i zainteresowania.',
    '7.7. Możliwe jest zablokowanie w łatwy sposób przez daną osobę udostępniania Google Analytics informacji o jej aktywności na stronie Serwisu Internetowego - w tym celu można zainstalować dodatek do przeglądarki udostępniany przez firmę Google Inc. dostępny tutaj: https://tools.google.com/dlpage/gaoptout?hl=pl.',
  ],
  p8: [
    '8. POSTANOWIENIA KOŃCOWE',
    '8.1. Sklep Internetowy może zawierać odnośniki do innych stron internetowych. Administrator namawia by po przejściu na inne strony, zapoznać się z polityką prywatności tam ustaloną. Niniejsza polityka prywatności dotyczy tylko Serwisu Internetowego Administratora.',
  ],
}

const pages = {
  index: {
    name: false,
    slug: slugs.index,
    menuId: false,
    seo: {
      title: {
        pl: 'AppClever - Software House Trójmiasto',
        en: 'AppClever - For Business with Clever ideas',
      },
      description: {
        pl:
          'Tworzymy aplikacje webowe w oparciu o potrzeby Twojego Biznesu. Przekaż nam swoją wizję i pozwól jej się rozwijać',
        en:
          'Tworzymy aplikacje webowe w oparciu o potrzeby Twojego Biznesu. Przekaż nam swoją wizję i pozwól jej się rozwijać',
      },
    },
  },
  about: {
    name: { pl: 'Poznaj Nas', en: 'Discover Us' },
    slug: slugs.about,
    menuId: 1,
    footerId: 3,
    sections: aboutSection.sections,
    seo: {
      title: {
        pl: 'Poznaj nas',
        en: 'Poznaj nas',
      },
      description: {
        pl:
          'Zespół programistów, projektantów oraz doświadczonych managerów, którzy każdego dnia dążą do doskonalenia swoich umiejętności i procesów twórczych',
        en:
          'Zespół programistów, projektantów oraz doświadczonych managerów, którzy każdego dnia dążą do doskonalenia swoich umiejętności i procesów twórczych',
      },
    },
  },
  coop: {
    name: { pl: 'Współpraca', en: 'Cooperation' },
    slug: slugs.coop,
    menuId: 2,
    footerId: 2,
    sections: [coopModelsSection, coopStagesSection],
    seo: {
      title: {
        pl: 'Współpraca: systemy IT dla biznesu',
        en: 'Współpraca: systemy IT dla biznesu',
      },
      description: {
        pl:
          'Niezależnie od tego, na jakim etapie obecnie znajduje się Twój projekt, możemy przekształcić go w zadanie dla naszego zespołu. ',
        en:
          'Niezależnie od tego, na jakim etapie obecnie znajduje się Twój projekt, możemy przekształcić go w zadanie dla naszego zespołu. ',
      },
    },
  },
  services: {
    name: { pl: 'Usługi', en: 'Services' },
    slug: slugs.services,
    menuId: 3,
    footerId: 1,
    sections: servicesSection.sections,
    seo: {
      title: {
        pl: 'Usługi: aplikacje webowe, e-commerce, systemy IT',
        en: 'Usługi: aplikacje webowe, e-commerce, systemy IT',
      },
      description: {
        pl:
          'Projektując aplikacje webowe, udoskonalamy procesy obsługiwane w sferze online. Dzięki dostosowaniu technologii i kreatywnych rozwiązań umożliwiamy rozwijanie się koncepcji biznesowych',
        en:
          'Projektując aplikacje webowe, udoskonalamy procesy obsługiwane w sferze online. Dzięki dostosowaniu technologii i kreatywnych rozwiązań umożliwiamy rozwijanie się koncepcji biznesowych',
      },
    },
  },
  valuation: {
    name: { pl: 'Wycena', en: 'Valuation' },
    slug: slugs.valuation,
    menuId: 4,
    footerId: 4,
    seo: {
      title: {
        pl: 'Wycena Projektu IT, Wycena Aplikacji Webowej',
        en: 'Wycena Projektu IT, Wycena Aplikacji Webowej',
      },
      description: {
        pl:
          'Niezależnie czy masz nowy pomysł na internetowy biznes, chciałbyś rozwinąć istniejące usługi w sferze online, czy potrzebujesz wsparcia w budowie systemu IT',
        en:
          'Niezależnie czy masz nowy pomysł na internetowy biznes, chciałbyś rozwinąć istniejące usługi w sferze online, czy potrzebujesz wsparcia w budowie systemu IT',
      },
    },
  },
  career: {
    name: { pl: 'Kariera', en: 'Career' },
    slug: slugs.career,
    hash: hashes.career,
    menuId: 5,
    footerId: 5,
    seo: {
      title: {
        pl: 'Praca dla programisty, UI Designer, Web Developer',
        en: 'Praca dla programisty, UI Designer, Web Developer',
      },
      description: {
        pl:
          'Jesteś programistą, projektantem, architektem systemów lub zajmujesz się zarządzaniem procesów IT i traktujesz swoją profesję nie tylko jako praca, ale także jako pasja?',
        en:
          'Jesteś programistą, projektantem, architektem systemów lub zajmujesz się zarządzaniem procesów IT i traktujesz swoją profesję nie tylko jako praca, ale także jako pasja?',
      },
    },
  },
  contact: {
    name: { pl: 'Kontakt', en: 'Contact' },
    slug: slugs.contact,
    menuId: 6,
    footerId: 6,
    seo: {
      title: {
        pl: 'Kontakt - Software House Gdańsk',
        en: 'Kontakt - Software House Gdańsk',
      },
      description: {
        pl:
          'Masz nowy pomysł lub wizję rozwoju istniejącego rozwiązania biznesowego? Potrzebujesz wsparcia specjalistów lub pozyskania zespołu deweloperskiego?',
        en:
          'Masz nowy pomysł lub wizję rozwoju istniejącego rozwiązania biznesowego? Potrzebujesz wsparcia specjalistów lub pozyskania zespołu deweloperskiego?',
      },
    },
  },
  policy: {
    name: { pl: 'Polityka Prywatności', en: 'Privacy Policy' },
    slug: slugs.policy,
    menuId: false,
    footerId: false,
    noIndex: true,
    seo: {
      title: {
        pl: 'Polityka Prywatności',
        en: 'Privacy Policy',
      },
      description: {
        pl:
          'Cel, podstawa, okres i zakres przetwarzania danych w serwisie internetowym',
        en:
          'Cel, podstawa, okres i zakres przetwarzania danych w serwisie internetowym',
      },
    },
  },
  download: {
    name: { pl: 'Materiały do pobrania', en: 'Materiały do pobrania' },
    slug: slugs.download,
    menuId: false,
    footerId: false,
    noIndex: true,
    seo: {
      title: {
        pl: 'Materiały do pobrania',
        en: 'Materiały do pobrania',
      },
      description: {
        pl: '',
        en: '',
      },
    },
  },
}

const contactForm = {
  message: {
    name: { pl: 'wiadomość', en: 'message' },
    type: 'area',
  },
  sender: {
    name: {
      pl: 'nadawca',
      en: 'sender',
    },
    type: 'text',
  },
  phone: {
    name: {
      pl: 'telefon',
      en: 'phone',
    },
    type: 'text',
  },
  email: {
    name: {
      pl: 'email',
      en: 'email',
    },
    type: 'text',
  },
  budget: {
    name: {
      pl: 'budżet',
      en: 'budget',
    },
  },
  agreement: {
    pl:
      'Wyrażam zgodę na przetwarzanie moich danych wskazanych w formularzu przez AppClever Sp. z o.o. z siedzibą w 80-337 Gdańsku, Ul. Sucha 15 pII w celu przekazywania mi informacji związanych z możliwością współpracy na adres e-mail wskazany w formularzu lub telefonicznie na podany przeze mnie numer, zgodnie z Ustawą z dnia 18 lipca 2002 o świadczeniu usług drogą elektroniczną (Dz.U. z 2013 poz. 1422).',
    en:
      'I consent to the processing of my data indicated in the form by AppClever Ltd with headquarters in 80-337 Gdańsk, Ul. Sucha 15 pII in order to provide me with information related to the possibility of cooperation to the e-mail address indicated in the form or by phone to the number provided by me, in accordance with the Act of 18 July 2002 on the provision of electronic services (Journal of Laws of 2013, item 1422) ).',
  },
  button: {
    default: { pl: 'WYŚLIJ!', en: 'SEND!' },
  },
  validation: {
    pl: 'Wypełnij formularz',
    en: 'Fill up the form',
  },
  success: {
    headline: {
      pl: 'Dziękujemy!',
      en: 'Thank you!',
    },
    description: {
      pl:
        'Twoja wiadomość została wysłana. Odpowiemy najszybciej jak to możliwe',
      en: 'Your message has been sent. We will answer as soon as possible',
    },
  },
  failed: {
    headline: {
      pl: 'Upss... Niepowodzenie',
      en: 'Och... Failure',
    },
    description: {
      pl:
        'Twoja wiadomość NIE została wysłana. Sprawdź połączenie z internetem',
      en:
        'Your message has NOT been sent. Please check your network connection',
    },
  },
}

const brand = {
  name: 'AppClever',
  address: {
    pl: ['Lecha Kaczyńskiego 22A/87', '80-365 Gdańsk, Polska'],
    en: ['Lecha Kaczyńskiego 22A/87', '80-365 Gdańsk, Poland'],
    location: '/',
  },
  email: 'biuro@appclever.pl',
  phone: '+48 530 990 732',
  partners: {
    pl: 'Partnerzy',
    en: 'Partners',
  },
}

const dictionary = {
  selectOption: {
    pl: 'Wybieram tę usługę',
    en: 'Choose this service',
  },
}

const productSection = {
  label: {
    pl: 'WKRÓTCE',
    en: 'SOON',
  },
  text: {
    pl:
      'Clever UI Kit to zestaw gotowych komponentów graficznych, z których może powstać Twoja aplikacja webowa. Już niedługo będziesz mógł dobrierać dowolne elementy oraz przygotowane sekcje z różnych styli, czy motywów. ',
    en:
      'Clever UI Kit to zestaw gotowych komponentów graficznych, z których może powstać Twoja aplikacja webowa. Już niedługo będziesz mógł dobrierać dowolne elementy oraz przygotowane sekcje z różnych styli, czy motywów. ',
  },
  tiles: [
    {
      title: 'Red Dressness',
      subtitle: ['women', 'casual'],
      description: 'Designed by best Italian tailors - just waiting for you',
      button: 'Learn More',
      photo: 'ladyyellow',
      colorBack: '#E9AF5A',
      colorFront: '#50412F',
    },
    {
      title: 'Flowered Feet',
      subtitle: ['elegant shoes'],
      description: 'Colorize your daily outlook with Flowered Feet',
      button: 'Order Now',
      photo: 'shoesblue',
      colorBack: '#90CEE5',
      colorFront: '#1D6298',
    },
    {
      title: 'Trainies 20',
      subtitle: ['sport', 'running'],
      description:
        'Check out bestselling Trainins with 50% discount this weekend',
      button: '50% OFF',
      photo: 'shoesred',
      colorBack: '#CC041D',
      colorFront: '#fff0f2',
    },
    {
      title: 'Baseball Dogy',
      subtitle: ['pet clothing'],
      description: 'Imagine your dog looked like that!',
      button: 'Cart Now',
      photo: 'doggreen',
      colorBack: '#00CF89',
      colorFront: '#246851',
    },
    {
      title: 'Honeysuckle Teal',
      subtitle: ['casual', 'men'],
      description: 'Get the Modern look with new Honeysucke shoes',
      button: '$49.99',
      photo: 'shoesteal',
      colorBack: '#00D2C7',
      colorFront: '#57474C',
    },
    {
      title: 'Readhead 2021',
      subtitle: ['newest', 'shoes'],
      description: 'Collection 21 incoming. Preorder opens 12/16',
      button: 'Explore',
      photo: 'shoesyellow',
      colorBack: '#FDAC00',
      colorFront: '#930023',
    },
  ],
  mockup: {
    expandables: [
      {
        title: 'Look, better, do better',
        text:
          'This is indeed our motto. We thing that the smart appearance improves your fettle and boosts your confidence. Our design works exactly this way by featuring the very best parts of your product',
      },
      {
        title: 'How clever Design works',
        text:
          'Clever UI Kit is a growing fast set of premade both graphic and web components. Everything is designed and programmed to match your expectations. It really does not matter if you need to buil an online store, portfolio page or an extensive IT System. The desired digital project is just at yout fingertips',
      },
    ],
    image: 'smilinggirl',
  },
}

module.exports = {
  pages,
  introSection,
  servicesSection,
  valuationNeededSection,
  coopModelsSection,
  coopStagesSection,
  techSection,
  careerSection,
  valuationSection,
  contactForm,
  aboutSection,
  contactSection,
  brand,
  slugs,
  dictionary,
  productSection,
  policyPage,
}
