import React from 'react'
import { colors, alpha } from '../style'
import bulb from '../assets/icons/bulb'
import Dot from '@material-ui/icons/FiberManualRecord'
import { s } from '../style'

const ThemeButton = ({ theme }) => {
  return (
    <div css={style}>
      <Dot css={[sDot, theme === 'light' ? sDotActive : sDotNoActive]} />
      <div css={[sBulb, theme === 'light' ? activeBulb : noactiveBulb]}>
        {bulb}
      </div>
    </div>
  )
}

const style = {
  [s.sss]: { display: 'none' },
  position: 'relative',
  transition: 'all 0.3s ease-out',
  borderRadius: 24,
  width: 48,
  height: 48,
  // display: 'flex',
  // justifyContent: 'center',
  // alignItems: 'center',
  outline: 'none',
  zIndex: 1000,
  cursor: 'pointer',
  transition: 'all 0.3s ease-out',
  color: colors.white,
  // backgroundColor: colors.black.concat(alpha[16]),
  '&:hover': {
    [s.hover]: { backgroundColor: colors.black.concat(alpha[60]) },
  },
}
const sBulb = {
  svg: {
    width: 20,
    height: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    path: { fill: colors.white.concat(alpha[100]) },
  },
}

const sDot = {
  transition: 'transform 0.3s 0.3s ease-out',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -63%)',
  // display: 'flex',
  // flexDirection: 'row',
  // justifyContent: 'center',
  // alignItems:'center',
  svg: {},
}
const sDotActive = {
  transform: 'translate(-50%, -63%) scale(1)',
}
const sDotNoActive = {
  transform: 'translate(-50%, -63%) scale(0)',
}

const activeBulb = {
  svg: {
    animation: 'wobble2 0.9s both',
    '@keyframes wobble2': {
      '0%': {
        transform: 'translate(-50%, -50%) scale3d(1, 1, 1)',
      },
      '30%': {
        transform: 'translate(-50%, -50%) scale3d(0.75, 1.25, 1)',
      },
      '40%': {
        transform: 'translate(-50%, -50%) scale3d(1.25, 0.75, 1)',
      },
      '50%': {
        transform: 'translate(-50%, -50%) scale3d(0.85, 1.15, 1)',
      },
      '65%': {
        transform: 'translate(-50%, -50%) scale3d(1.05, 0.95, 1)',
      },
      '75%': {
        transform: 'translate(-50%, -50%) scale3d(0.95, 1.05, 1)',
      },
      '100%': {
        transform: 'translate(-50%, -50%) scale3d(1, 1, 1)',
      },
    },
  },
}
const noactiveBulb = {
  svg: {
    animation: 'wobble 0.9s both',
    '@keyframes wobble': {
      '0%': {
        transform: 'translate(-50%, -50%) scale3d(1, 1, 1)',
      },
      '30%': {
        transform: 'translate(-50%, -50%) scale3d(0.75, 1.25, 1)',
      },
      '40%': {
        transform: 'translate(-50%, -50%) scale3d(1.25, 0.75, 1)',
      },
      '50%': {
        transform: 'translate(-50%, -50%) scale3d(0.85, 1.15, 1)',
      },
      '65%': {
        transform: 'translate(-50%, -50%) scale3d(1.05, 0.95, 1)',
      },
      '75%': {
        transform: 'translate(-50%, -50%) scale3d(0.95, 1.05, 1)',
      },
      '100%': {
        transform: 'translate(-50%, -50%) scale3d(1, 1, 1)',
      },
    },
  },
}

export default ThemeButton
