import React, { useState } from 'react'
import { colors, s, alpha } from '../style'

const LanguageButton = ({ theme, language }) => {
  const [hover, setHover] = useState(false)
  const langName = language === 'pl' ? 'Polski' : 'English'

  return (
    <div
      css={[style, hover && styleHover]}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div css={{ position: 'relative' }}>
        <p css={[sLlong, center, !hover && { [s.md]: { opacity: 0 } }]}>
          {langName}
        </p>
        <p css={[sLshort, center, hover && { [s.md]: { opacity: 0 } }]}>
          {language}
        </p>
      </div>
    </div>
  )
}

const style = {
  transition: 'all 0.3s ease-out',
  borderRadius: 24,
  width: 48,
  height: 48,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  outline: 'none',
  zIndex: 1000,
  cursor: 'pointer',
  transition: 'all 0.3s ease-out',
  color: colors.white,
  // backgroundColor: colors.black.concat(alpha[16]),
  '&:hover': {
    [s.hover]: { backgroundColor: colors.black.concat(alpha[60]) },
  },
}

const styleHover = {
  [s.md]: { width: 96 },
}

const center = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  lineHeight: 1,
  fontFamily: 'Quicksand',
  transition: 'opacity 0.3s ease-in-out',
}

const sLlong = {
  textTransfotm: 'capitalize',
  fontSize: '0.875rem',
  fontWeight: 700,
  backgroundColor: colors.black.concat(alpha[16]),
  [s.sm_down]: { opacity: 0 },
}
const sLshort = {
  textTransform: 'uppercase',
  fontSize: '1rem',
  fontWeight: 700,
}

export default LanguageButton
